//================================================================
//  Component: Download Input
//================================================================

//  Purpose: Checkbox that handles files and folder downloads


//  Properties:
//    - row = {object, current row in the table}
//    - filesToDownload = {useState, holds waiting to download files}
//    - setFilesToDownload = {useState, holds waiting to download files}


//  Example:
//      <DownloadInput
//          row={row}
//          filesToDownload={filesToDownload}
//          setFilesToDownload={setFilesToDownload}
//      ></DownloadInput>

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//Contexts


//Functions

//Components
import FileImage from '../../../Components/FileImage/FileImage';

//Images


export default function DownloadInput({
    row,
    filesToDownload,
    setFilesToDownload,
}) {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const navigate = useNavigate();
    const location = useLocation();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Current location 
    const [route, setRoute] = useState(location.search);

    // checkbox state
    const [checked, setChecked] = useState(false);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Adding & Removing files to/from 'filesToDownload'
    function handleCheck(checked, row) {

        setChecked(checked);

        if (checked === true) {

            return setFilesToDownload([
                ...filesToDownload,
                row
            ]);

        }

        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
        const index = filesToDownload.findIndex((object) => row.fileid === object.fileid);

        filesToDownload.splice(index, 1);
        setFilesToDownload([...filesToDownload]);

    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onChange
    // 1. If the route changes, uncheck any checked items
    useEffect(() => {

        if (route === location.search) return;

        setRoute(location.search);
        setChecked(false);
        setFilesToDownload([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // onChange
    // 1. The downloads have been completed, uncheck the box
    useEffect(() => {

        if (filesToDownload.length > 0) return;

        setChecked(false);

    }, [filesToDownload]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <td className='py-[15px] px-[20px] font-medium'>
            {
                row?.contenttype === 'folder' ? (

                    // Folder
                    <div className='flex flex-row gap-2'>

                        <label className='w-[55px]'></label>
                        <FileImage
                            contenttype={row?.contenttype}
                        ></FileImage>
                        <span className='self-center underline cursor-pointer' onClick={() => navigate(`/browse/${row?.datasetid}?path=${row?.folderpath}${row?.filename}/`)}>{row?.filename}</span>
                    </div>

                ) : (

                    // File
                    <div className='flex flex-row gap-2'>

                        {/* Checkbox */}
                        <label className='Radio-Buttons-Container'>
                            <input
                                className='Checkmark'
                                type='checkbox'
                                checked={checked}
                                onChange={(e) => handleCheck(e.target.checked, row)}
                            ></input>
                            <span className='Radio-Checkmark'></span>
                        </label>

                        {/* Checkbox */}
                        <FileImage
                            contenttype={row?.contenttype}
                        ></FileImage>
                        <span className='self-center'>{row?.filename}</span>
                    </div>

                )

            }

        </td>
    )

}
