//================================================================
//  Component: SearchResults of the Search Page
//================================================================

//  Purpose: Table that contains the search results for the Search Page


//  Properties:
//    - search = {useReducer, holds the search result docs and state}


//  Example:
//      <SearchResults
//          search={search}
//      ></SearchResults>

//================================================================


//Libraries
import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { SetToast } from '../../../Library/GlobalContexts';

//Components
import FileImage from '../../../Components/FileImage/FileImage';

//Functions
import ConvertDate from '../../../Library/ConvertDate';
import DownloadBlob from '../../../Library/DownloadBlob';


export default function SearchResults({
    search,
}) {

    //------------------------------------------------------
    //  useContext & React Router
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    const navigate = useNavigate();

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    function handleDownload(object) {

        // Open the toast
        setToast({
            'type': 'pending', // info, pending, success, warning, error
            'message': 'Preparing for download',
        });

        DownloadBlob(`${object.folderpath}${object.filename}`, object.datasetid).then((blob) => {

            // Create URL from blob
            const url = window.URL.createObjectURL(blob);

            // Create new Element -> Add the file name & URl
            const a = document.createElement('a');
            a.download = object.filename;
            a.href = url;

            // Trigger download & Cleanup
            a.click();

            // Close toast
            setToast({
                'type': '',
                'message': '',
            });

        }).catch((error) => {
    
            console.log(error);
      
            // Set error on toast
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to prepare link',
            });

        });

    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div className=' border-t-[1px] border-solid border-[#D8D8D8D8] pt-[20px]'>

            {

                search.results.length === 0 ?

                    <>
                        No results found...
                    </>

                    :

                    search.results.map((object, index) => (

                        <div key={index} className='my-[20px]'>

                            {/* Filename, link & path */}
                            <div className=' flex flex-row gap-2'>
                                <FileImage contenttype={object.contenttype}></FileImage>
                                {
                                    // We dont support folder downloads
                                    object?.contenttype === 'folder' ? (

                                        <span className='self-center cursor-default'>{object?.filename}</span>

                                    ) : (

                                        <span className='self-center underline cursor-pointer' onClick={() => handleDownload(object)}>{object?.filename}</span>

                                    )

                                }
                            </div>

                            {/* Folderpath */}
                            <span className='text-[#535353] text-sm font-light max-w-[200px] un overflow-hidden text-ellipsis underline cursor-pointer' onClick={() => navigate(`/browse/${object.datasetid}?path=${object.folderpath}`)}>{object.datasetid}{object.folderpath}</span>

                            {/* Metadata */}
                            <div className='grid grid-cols-3 w-full gap-1 mt-[15px] text-sm text-nowrap max-w-[1000px]'>

                                <div className='flex gap-1'>
                                    Repository:
                                    <span className='text-[#535353] font-light overflow-hidden text-ellipsis underline cursor-pointer' onClick={() => navigate(`/browse/${object.datasetid}?path=/`)}>{object.datasetid}</span>

                                </div>

                                <div className='flex gap-1'>
                                    Application:
                                    <label className='font-light max-w-[180px] overflow-hidden text-ellipsis'>{object.application}</label>
                                </div>

                                <div className='flex gap-1'>
                                    Created:
                                    <label className='font-light max-w-[180px] overflow-hidden text-ellipsis'>{ConvertDate(object.created)}</label>
                                </div>

                                <div className='flex gap-1'>
                                    Modified:
                                    <label className='font-light max-w-[180px] overflow-hidden text-ellipsis'>{ConvertDate(object.lastupdated)}</label>
                                </div>

                                <div className='flex gap-1'>
                                    Size:
                                    <label className='font-light max-w-[180px] overflow-hidden text-ellipsis'>{object.filesize} MB</label>
                                </div>

                                <div className='flex gap-1'>
                                    File Type:
                                    <label className='font-light max-w-[180px] overflow-hidden text-ellipsis'>{object.contenttype}</label>
                                </div>

                            </div>

                        </div>

                    ))
            }

        </div>

    )

}
