//------------------------------------------------------
//  Initialize React
//------------------------------------------------------

import React from 'react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';


//------------------------------------------------------
//  Initialize redux, useContexts & styling
//------------------------------------------------------

import GlobalContexts from './Library/GlobalContexts';
import './index.css';


//------------------------------------------------------
//  Initialize Firebase & Auth
//  We are using MS App Reg, see the readme for more information
//------------------------------------------------------

import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { firebaseConfig } from './Library/FirebaseConfig';
import AuthProvider from "./Library/AuthProvider";
import LoginHandler from './Library/LoginHandler';

//Initialize Firebase App
const app = initializeApp({ ...firebaseConfig });


//------------------------------------------------------
//  reCAPTCHA v3
//  https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223379587304/How+to+setup+Firebase+App+Check+reCAPTCHA
//------------------------------------------------------

if (process.env.REACT_APP_RECAPTCHA_SITE_KEY !== 'undefined') {

  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true
  });

}


//------------------------------------------------------
//  Connect to Firebase Emulators
//------------------------------------------------------

if (process.env.REACT_APP_START_EMULATOR === 'true') {

  // https://firebase.google.com/docs/emulator-suite/connect_firestore#android_apple_platforms_and_web_sdks
  const db = getFirestore();
  connectFirestoreEmulator(db, '127.0.0.1', 5000);

  // https://firebase.google.com/docs/emulator-suite/connect_storage#instrument_your_app_to_talk_to_the_emulators
  const storage = getStorage();
  connectStorageEmulator(storage, '127.0.0.1', 9199);

}


//------------------------------------------------------
//  Initialize Session storage
//------------------------------------------------------

if (sessionStorage.getItem('getAppStatus') === null) {

  sessionStorage.setItem('getAppStatus', 'unauthenticated');

}


//------------------------------------------------------
//  Return the App
//------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <GlobalContexts>
      <AuthProvider>
        <LoginHandler></LoginHandler>
      </AuthProvider>
    </GlobalContexts>
  </StrictMode>
);


//------------------------------------------------------